import { IThunderboltEntry } from '@wix/editor-elements-types';

import {
  registerComponentTypeUiTypes,
  UiTypesRegistrationMap,
} from '../../registration/registration';

const UiTypes: UiTypesRegistrationMap = {
  '': () => [
    import(
      './viewer/skinComps/BaseMenu/TinyMenuFullScreenSkin.skin' /* webpackChunkName: "TinyMenu" */
    ),
    import('./viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu" */),
  ],
  TinyMenuFullScreenSkin: () => [
    import(
      './viewer/skinComps/BaseMenu/TinyMenuFullScreenSkin.skin' /* webpackChunkName: "TinyMenu_TinyMenuFullScreenSkin" */
    ),
    import(
      './viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu_TinyMenuFullScreenSkin" */
    ),
  ],
  TinyMenuPullFromLeftSkin: () => [
    import(
      './viewer/skinComps/BaseMenu/TinyMenuPullFromLeftSkin.skin' /* webpackChunkName: "TinyMenu_TinyMenuPullFromLeftSkin" */
    ),
    import(
      './viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu_TinyMenuPullFromLeftSkin" */
    ),
  ],
  TinyMenuPullFromRightSkin: () => [
    import(
      './viewer/skinComps/BaseMenu/TinyMenuPullFromRightSkin.skin' /* webpackChunkName: "TinyMenu_TinyMenuPullFromRightSkin" */
    ),
    import(
      './viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu_TinyMenuPullFromRightSkin" */
    ),
  ],
  TinyMenuPullFromTopSkin: () => [
    import(
      './viewer/skinComps/BaseMenu/TinyMenuPullFromTopSkin.skin' /* webpackChunkName: "TinyMenu_TinyMenuPullFromTopSkin" */
    ),
    import(
      './viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu_TinyMenuPullFromTopSkin" */
    ),
  ],
  TinyMenuSkin: () => [
    import(
      './viewer/skinComps/TinyMenuSkin/TinyMenuSkin.skin' /* webpackChunkName: "TinyMenu_TinyMenuSkin" */
    ),
    import(
      './viewer/TinyMenu.controller' /* webpackChunkName: "TinyMenu_TinyMenuSkin" */
    ),
  ],
};

const entry: IThunderboltEntry = {
  loadComponent: registerComponentTypeUiTypes('TinyMenu', UiTypes),
};

export default entry;
